import React, { useEffect, useState } from 'react';
import axios from '../axios.jsx'; // Asegúrate de que la ruta sea correcta
import { XMLParser } from 'fast-xml-parser';
import Swal from 'sweetalert2';
import '../styles.css'; // Asegúrate de que tu archivo de estilos esté correctamente importado

const App = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // 6 elementos para 2 filas de 3 columnas
  const apiUrl = 'https://apimonitoreo.vspamazonas.com.co/news';

  const fetchNews = async () => {
    setLoading(true);
    try {
      const response = await axios.get(apiUrl);
      const parser = new XMLParser();
      const result = parser.parse(response.data);
      const newsItems = result.rss.channel.item;
      setNews(Array.isArray(newsItems) ? newsItems : []);
    } catch (error) {
      console.error('Error al obtener las noticias:', error);
      Swal.fire({
        title: 'Error',
        text: 'No se pudieron cargar las noticias.',
        icon: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  // Calcular el índice de las noticias para mostrar en la página actual
  const indexOfLastNews = currentPage * itemsPerPage;
  const indexOfFirstNews = indexOfLastNews - itemsPerPage;
  const currentNews = news.slice(indexOfFirstNews, indexOfLastNews);

  // Cambiar de página
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Calcular el total de páginas
  const totalPages = Math.ceil(news.length / itemsPerPage);

  // Función para limitar el texto a un número específico de palabras
  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
  };

  return (
    <div className="container">
      <header>
        <button onClick={() => window.open('/login', '_blank')} className="login-button">
          Iniciar Sesión
        </button>
      </header>
      <main>
        {loading ? (
          <p>Cargando noticias...</p>
        ) : (
          <div className="news-container">
            {currentNews.map(item => (
              <a href={item.link} target="_blank" rel="noopener noreferrer" className="news-card" key={item.guid}>
                <h3>{limitWords(item.title, 20)}</h3>
                <p>{limitWords(item.description, 50)}</p>
                <p><strong>Publicado:</strong> {new Date(item.pubDate).toLocaleString()}</p>
              </a>
            ))}
          </div>
        )}
        {/* Paginación simplificada */}
        <div className="pagination">
          <button onClick={handlePrevious} disabled={currentPage === 1}>
            Anterior
          </button>
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            Siguiente
          </button>
        </div>
      </main>
    </div>
  );
};

export default App;








