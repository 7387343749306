import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from './axios.jsx';



const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within a AuthProvider");
  return context;
};

export const AuthProvider = ({ children }) => {
  const [authenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [respuesta, setrespuesta] = useState();


    useEffect(()=>{
      const cheuser = async () => {
        await fetch('https://apimonitoreo.vspamazonas.com.co/get-cookie', {
          method: 'GET',
          credentials: 'include',
          withCredentials: true,
        })
          .then(response => response.json())
          .then(data => {
            setrespuesta(data.token)
          }).catch(error => console.error('Error:', error));
        cheuser();
  
      }
    })


  useEffect(() => {    
    const checkLogin = async () => {
      if (respuesta === 0) {
        console.log('NO HAY TOKEN')
        return setIsAuthenticated(false)
      }

      try {
        console.log('SI HAY TOKEN')
        const res = await axios.get('/login')

        console.log(res);
        if (res.data === 0) return setIsAuthenticated(false);
        setIsAuthenticated(true);
        setUser(res.data.username);
        setLoading(false);


      } catch (error) {
        setIsAuthenticated(false);
        setLoading(false);
      }
    }
    checkLogin();

  }, []);

  const login = (userdata) => {
    setIsAuthenticated(true);
    setUser(userdata)
  };

  const logout = () => {
    // Lógica para cerrar sesión

    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ user, authenticated, login, logout, loading, }}>
      {children}
    </AuthContext.Provider>
  );
};


