import React, { useState } from 'react';
import axios from '../axios.jsx';
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../AuthContext.jsx';

import Swal from 'sweetalert2'


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
  

        await axios.post('/login', { username, password },
         ).then(res => {
            if (res.data.Login) {
              
                    Swal.fire({
                        title: 'Bienvenido',
                        text: res.data.username,
                        icon: 'success',
                        confirmButtonText: 'Cool',
                        timer: 800,
                    })
                    login(res.data.username);
                navigate('/app')
            }else

            Swal.fire({
                title: 'Error',
                text: 'Usuario y/o Password incorrectas',
                icon: 'error',
                confirmButton: 'true',
                timer: false,
            })

        })
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4">Login</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">Username</label>
                                    <input type="text" className="form-control" id="username" placeholder="Ingrese su usuario" required value={username} onChange={(e) => setUsername(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="password" placeholder="Ingrese su contraseña" required value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="d-grid">
                                    <button type="submit" className="btn btn-primary">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
