import axios from '../axios.jsx';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useAuth} from '../AuthContext';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'





const URI = 'blogs/';

const CompShowBlogs = () => {
    const [blogs, setBlog] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const blogsPerPage = 10;
    const { user, logout } = useAuth();
    const [selecteddate, setselected] = useState();
    const [selecteddate2, setSelectedDate] = useState('');
    const [sending, setSending] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const navigate = useNavigate()


    const handleDeleteBlog = (blogId) => {
        Swal.fire({
          title: '¿Estás seguro?',
          text: 'Esta acción no se puede deshacer.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Sí, eliminarlo'
        }).then((result) => {
          if (result.isConfirmed) {
            // Llamada a la función para eliminar el blog
            deleteBlog(blogId);
      
            // Opcional: Mostrar un mensaje de éxito después de eliminar
            Swal.fire({
              title: 'Eliminado',
              text: 'El monitoreo ha sido eliminado correctamente.',
              icon: 'success',
              timer: 2000, // Opcional: Cerrar automáticamente después de 2 segundos
              showConfirmButton: false
            });
          }
        });
      };



    const handlelogout = async () =>  {

        await axios.post('/login/logout', {
            withCredentials: true,
          });

          logout();

        navigate('/')
    }

    const handleexport = async () => {
        try {
          const response = await axios.get('/export',{ responseType: 'blob', withCredentials: true });
      
          // Crea un objeto URL para el archivo de Excel
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          // Crea un enlace en el DOM
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Monitoreo_de_medios.xlsx'); // Nombre del archivo de Excel
      
          // Simula un clic en el enlace para iniciar la descarga
          document.body.appendChild(link);
          link.click();
      
          // Limpia el objeto URL y elimina el enlace del DOM
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error al exportar:', error);
          // Maneja el error según sea necesario
        }
      };

    useEffect(() => {
        getBlogs()
    }, []);

    const handleOpenModal2 = () => {
        setShowModal2(true);
    };

    // Función para cerrar el modal
    const handleCloseModal2 = () => {
        setShowModal2(false);
    };


    const handleOpenModal = () => {
        setShowModal(true);
    };

    // Función para cerrar el modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const SendMail = async () => {
        if (!selecteddate2) {
            alert('Por favor, seleccione una fecha válida.');
        } else {
            try {
                setSending(true); // Activar estado de envío
                const response = await axios.get(
                    `/sendmail?selectedDate2=${selecteddate2}`, { responseType: 'blob' }
                );
                // Envío exitoso, cierra el modal
                alert('Enviado correctamente.');
                setShowModal2(false);
                setSelectedDate(''); // Limpia el estado al cerrar el modal
            } catch (error) {
                alert('No se encontraron registros de esta fecha.');
            } finally {
                setSending(false); // Desactivar estado de envío después de la solicitud
            }
        }
    };

    const handleGeneratePDF = async () => {

        if (!selecteddate) {
            alert('Por favor, seleccione una fecha válida.');
          } else {
            try {
                const response = await axios.get(
                    `/generate-pdf?selectedDate=${selecteddate}`, { responseType: 'blob' }
                
                );
    
                // Crear un enlace de descarga y hacer clic en él
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'MMCR '+ selecteddate +'.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                alert('No se encontraron registros de esta fecha.');
                
            }
            setShowModal(false);
            setselected(''); // Limpia el estado al cerrar el modal
          }
    };

    const getBlogs = async () => {
        try {
            const res = await axios.get(URI);
            console.log(res)
            setBlog(res.data.reverse()); // Mostrar del último al primero
        } catch (error) {
            console.error('Error fetching blogs:', error);
        }
    };

    const deleteBlog = async (id) => {
        try {
            await axios.delete(`${URI}${id}`);
            getBlogs();
        } catch (error) {
            console.error(`Error deleting blog with id ${id}:`, error);
        }
    };

    const pageCount = Math.ceil(blogs.length / blogsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const displayBlogs = blogs
        .slice(pageNumber * blogsPerPage, (pageNumber + 1) * blogsPerPage)
        .map((blog) => (
            <tr key={blog.id}>
                <td>{blog.createdAt}</td>
                <td>{blog.monitor}</td>
                <td className='text-truncate' style={{ maxWidth: '200px' }}>
                    {blog.titulo}
                </td>
                <td>
                    <Link to={`/edit/${blog.id}`} className='btn btn-info mr-1'>
                        <i className='fas fa-edit'></i>
                    </Link>

                    <button onClick={() => handleDeleteBlog(blog.id)} className='btn btn-danger'>
                        <i className='fas fa-trash-alt'></i>
                    </button>
                </td>
            </tr>
        ));

        return (
            <div className="container">
              <div>
                {user ? (
                  <p className="mt-2">Bienvenido, {user}</p>
                ) : (
                  <p>No has iniciado sesión.</p>
                )}
                <div className="button-container">
  <Button onClick={handlelogout} style={{ marginRight: '10px' }}>Logout</Button>
  <Button onClick={handleexport}>Exportar matriz</Button>
</div>
            
            
            
            
                            <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title className='col text-center'>Exportar Reporte</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <label className="mb-1">Fecha del reporte</label>
                                    <input
                                        type="date"
                                        className="form-control mb-2"
                                        required
                                        value={selecteddate}
                                        onChange={(e) => setselected(e.target.value)} />
            
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseModal}>
                                        Cerrar
                                    </Button>
                                    <Button variant="primary" onClick={handleGeneratePDF}>
                                        Exportar
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            
                            <Modal show={showModal2} onHide={handleCloseModal2}>
                                <Modal.Header closeButton>
                                    <Modal.Title className='col text-center'>Enviar reporte por correo electronico</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <label className="mb-1">Fecha del reporte</label>
                                    <input
                                        type="date"
                                        className="form-control mb-2"
                                        required
                                        value={selecteddate}
                                        onChange={(e) => setSelectedDate(e.target.value)} />
            
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseModal2}>
                                        Cerrar
                                    </Button>
                                    <Button variant="primary" onClick={SendMail} disabled={sending}>
                                        Enviar
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            
            
                            
            
            
            
                            <div className='row'>
                                <div className='col'>
                                    <Link to='/create' className='btn btn-primary mt-2 mb-2'>
                                        <i className='fas fa-plus'></i>
                                    </Link>
                                    <button onClick={handleOpenModal} className="btn btn-primary mt-2 mb-2" style={{ marginLeft: '10px' }}>
                                        <i className="fa-solid fa-print"></i>
                                    </button>
                                    <button onClick={handleOpenModal2} className="btn btn-primary mt-2 mb-2" style={{ marginLeft: '10px' }}>
                                    <i className="fa-solid fa-envelope"></i>
                                    </button>
                                </div>
                                <div className='table-responsive'>
                                    <table className='table table-striped'>
                                        <thead className='table-primary'>
                                            <tr>
                                                <th>Fecha de noticia</th>
                                                <th>Monitor de medios</th>
                                                <th>Titulo de noticia</th>
                                                <th>Accion</th>
                                            </tr>
                                        </thead>
                                        <tbody>{displayBlogs}</tbody>
                                    </table>
                                </div>
                                <ReactPaginate
                                    previousLabel={<i className='fas fa-chevron-left'></i>}
                                    nextLabel={<i className='fas fa-chevron-right'></i>}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={'pagination justify-content-center'} // Centra los botones horizontalmente
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    disabledClassName={'page-item disabled'}
                                    activeClassName={'page-item active'}
                                />
                            </div>
                        </div>
                    </div>
                );
            };
            
            export default CompShowBlogs;
