import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = () => {
  const { authenticated, loading} = useAuth();

  console.log(authenticated,loading)

 if(!authenticated && !loading) return <Navigate to={"/"} replace/>;
 return <Outlet/>
}
export default PrivateRoute;
