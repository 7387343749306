import axios from '../axios.jsx';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const URI = 'blogs/'
const URI2 = 'gestor/'

const CompEditBlog = () => {
  const [fechaIngreso, setFechaIngreso] = useState('')
  const [semanaepi, setsemanaepi] = useState('')
  const [monitor, setmonitor] = useState('')
  const [tp_noticia, settpnoticia] = useState('')
  const [medio, setmedio] = useState('')
  const [gestor_sat, setgestor] = useState('')
  const [verificacion, setverifi] = useState('')
  const [titulo, settitulo] = useState('')
  const [resumen, setresumen] = useState('')
  const [enlace, setenlace] = useState('')

  const navigate = useNavigate()
  const { id } = useParams()
  const [gestores, setgestores] = useState([])

  //procedimiento para actualizar
  const update = async (e) => {
    e.preventDefault()
    await axios.put(URI + id, {
      createdAt: fechaIngreso, semana: semanaepi, monitor: monitor, tp_noticia: tp_noticia, medio: medio,
      gestor_sat: gestor_sat, verificacion: verificacion, titulo: titulo, resumen: resumen, enlace: enlace
    })
    navigate('/app')
  }

  useEffect(() => {
  

    getBlogById()

  },[]);

  const getBlogById = async () => {
    const res = await axios.get(URI + id)
    setFechaIngreso(res.data.createdAt)
    setsemanaepi(res.data.semana)
    setmonitor(res.data.monitor)
    settpnoticia(res.data.tp_noticia)
    setmedio(res.data.medio)
    setgestor(res.data.gestor_sat)
    setverifi(res.data.verificacion)
    settitulo(res.data.titulo)
    setresumen(res.data.resumen)
    setenlace(res.data.enlace)
    try {
      const res = await axios.get(URI2);
      setgestores(res.data); // asumiendo que res.data es un array
    } catch (error) {
      console.error('Error al obtener opciones:', error);
    }
  }

  return (
    <div className="container">
      <h2 className="text-center mb-5 mt-3">Formulario de Monitoreo de Medios y Comunicaciones</h2>
      <form onSubmit={update}>
        <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <label className="mb-1">Fecha de ingreso</label>
              <input
                type="date"
                className="form-control mb-2"
                value={fechaIngreso}
                onChange={(e) => setFechaIngreso(e.target.value)}
              />
              <label className="mb-1">Semana epidemiologica</label>
              <input
                type="text"
                className="form-control mb-2"
                value={semanaepi}
                onChange={(e) => setsemanaepi(e.target.value)}
              />
              <label className="mb-1">Monitor de medios</label>
              <input
                type="text"
                className="form-control mb-2"
                value={monitor}
              />
              <label className="mb-1">Tipo de noticia</label>
              <select
                className="form-control mb-2"
                value={tp_noticia}
                onChange={(e) => settpnoticia(e.target.value)}
              >
                <option value="">Selecciona una opcion</option>
                <option value="LOCAL">Local</option>
                <option value="NACIONAL">Nacional</option>
                <option value="INTERNACIONAL">Internacional</option>
              </select>
              <label className="mb-1">Medio</label>
              <select
                className="form-control mb-2"
                value={medio}
                onChange={(e) => setmedio(e.target.value)}
              >
                <option value="">Selecciona una opcion</option>
                <option value="PAGINA WEB">Pagina web</option>
                <option value="RADIO">Radio</option>
                <option value="REDES SOCIALES">Redes sociales</option>
                <option value="TV">Tv</option>
              </select>
              <label className="mb-1">Gestor SAT</label>
              <select
                className="form-control mb-2"
                value={gestor_sat}
                onChange={(e) => setgestor(e.target.value)}
              >
                <option value="">Selecciona una opción</option>
                {gestores.map(option => (
                  <option key={option.id} value={option.nombre}>
                    {option.nombre}
                  </option>
                ))}
              </select>
              <label className="mb-1">Verificacion</label>
              <select
                className="form-control mb-2"
                value={verificacion}
                onChange={(e) => setverifi(e.target.value)}
              >
                <option value="">Selecciona una opcion</option>
                <option value="N/A">N/A</option>
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </select>

            </div>
            <div className="col-md-6">
              <label>Titulo de la noticia</label>
              <textarea
                className="form-control"
                value={titulo}
                onChange={(e) => settitulo(e.target.value)}
                style={{ minHeight: '50px', maxHeight: '100px', overflowY: 'auto' }}
              />
              <label>Resumen de la noticia</label>
              <textarea
                className="form-control"
                value={resumen}
                onChange={(e) => setresumen(e.target.value)}
                style={{ minHeight: '300px', maxHeight: '600px', overflowY: 'auto' }}
              />
              <label>Enlace de la noticia</label>
              <input
                className="form-control"
                value={enlace}
                onChange={(e) => setenlace(e.target.value)}
              //style={{ minHeight: '300px', maxHeight: '600px', overflowY: 'auto' }}
              />

            </div>

          </div>
        </div>

        <div className="form-group text-center">
          <button type="submit" className="btn btn-primary">
            Enviar
          </button>
        </div>
      </form>
    </div>
  )

}

export default CompEditBlog