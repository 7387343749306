
import './App.css';
// importamos los componentes

import CompShowBlogs from './blog/ShowBlogs';
import CompCreateBlog from './blog/CreateBlog';
import CompEditBlog from './blog/EditBlog';
import Login from './blog/Login';
import PrivateRoute from './PrivateRoute';
import CompEios from './blog/paginaM';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';


function App() {
  return (
    <AuthProvider>
    <div className="App">
      <header className="App-header">
        <h1>Monitoreo de medios de comunicación y rumores</h1>
      </header>
      <BrowserRouter>
        <Routes>
          
        <Route path="/" element={<CompEios />} />
        <Route path="/login" element={<Login />} />

        <Route element = {<PrivateRoute/>} >
        <Route path="/app" element={<CompShowBlogs />} />
        <Route path="/edit/:id" element={<CompEditBlog/>} />
        <Route path="/create" element={<CompCreateBlog />} />

        </Route>

          
        </Routes>
      </BrowserRouter>
    </div>
    </AuthProvider>
  );
}

export default App;
